import { absoluteUrl } from 'utils/urlGenerator';

type GetExpertLink = ({
  domainConfig,
  projectId,
  alias,
}: {
  domainConfig: Runtime['domainConfig'];
  projectId: ProjectType['id'];
  alias: ATExpertType['alias'];
}) => string;

/**
 * Функция генерации ссылки на страницу эксперта
 * @param props.domainConfig - объект данных о домене;
 * @param props.projectId - id проекта;
 * @param props.alias - никнейм эксперта.
 */
// TODO(NEWS-11862): Избавиться от runtime в сторону более простого подхода
export const getExpertLink: GetExpertLink = ({
  domainConfig,
  projectId,
  alias,
}) => absoluteUrl(domainConfig, projectId, `/experts/${alias}/`);
